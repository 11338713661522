<template>

  <b-modal
      v-model="show"
      content-class="package-modal address-modal rounded-8"
      dialog-class="wameed-modal-md"
      no-fade
      no-footer
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t('users.client.addresses') }}
        </h3>
      </div>
    </template>


    <div class="address-modal-body">


      <div class="address-card" v-for="(address,index) in addresses" :key="index">

        <div class="tile-text between mb">
          <div class="text">#</div>
          <div class="value">{{ index + 1 }}</div>
        </div>
        <div class="tile-text between mb">
          <div class="text">{{ $t('users.card.category') }}</div>
          <div class="value">{{ address.is_default === 1 ? $t('common.default') : $t('common.other_address') }}</div>
        </div>
        <div class="tile-text between mb">
          <div class="text">{{ $t('users.card.name') }}</div>
          <div class="value">{{ address.name }}</div>
        </div>
        <div class="tile-text between mb">
          <div class="text">{{ $t('users.card.type') }}</div>
          <div class="value">{{ address.type }}</div>
        </div>
        <div class="tile-text between mb">
          <div class="text">{{ $t('users.card.place') }}</div>
          <div class="value">{{ address.note }}</div>
        </div>
        <div class="tile-text between mb">
          <div class="text">{{ $t('users.card.address_on_map') }}</div>
          <a class="value link text-main" :href="geolocationLink(address.geo_location)"
             target="_blank">{{ $t('users.table.show_addresses') }}</a>
        </div>

      </div>
    </div>


    <template slot="modal-footer" class="d-none">
      <div class="d-none"></div>
    </template>
  </b-modal>

</template>

<script>


export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    addresses: {
      type: Array,
      default: null,
    },

  },

  data() {
    return {


      showSuccessModal: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

  },


  methods: {

    closeModal() {
      this.show = false;
    },

    geolocationLink(temp) {
      let location = temp.split(',');
      let locationLat = location[0];
      let locationLng = location[1];
      return `https://www.google.com/maps/place/${locationLat},${locationLng}/@${locationLat},${locationLng},15z`
    }

  },
};
</script>
